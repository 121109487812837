import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

import { Bubble } from "react-chartjs-2";
import CircularProgress from "@mui/material/CircularProgress";

import ChartDataLabels from "chartjs-plugin-datalabels";
import * as Constants from "./constants";
import Paper from "@mui/material/Paper";
import DriverDetail from "./DriverDetail";
import Link from "@mui/material/Link";

const driverIcon36 = new Image();
driverIcon36.src = "sports_motorsports_black_36dp.svg";

const driverIcon24 = new Image();
driverIcon24.src = "sports_motorsports_black_24dp.svg";

class DriversView extends React.Component {
  constructor(props) {
    super(props);
    //this.myRef = React.createRef();
    this.state = {
      isFetching: true,
      top: 0,
      left: 0,
      value: 0,
      data: [],
    };

    //console.log("constructor");
  }

  setPositionAndData = (top, left, date, value) => {
    const showTooltip = true;
    this.setState({ showTooltip, top, left, date, value });
  };
  hideTooltip = () => {
    const showTooltip = false;
    this.setState({ showTooltip });
  };

  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate() {
    this.fetchData();
  }

  fetchData = () => {
    const podiumFilter =
      this.props.minPodiums > 0
        ? `FILTER(?podiumCount >= ${this.props.minPodiums}) . `
        : "";

    const minRacesFilter =
      this.props.minRaces > 1
        ? "FILTER(?resultCount >= " + this.props.minRaces + ").\n"
        : "";

    const dateFilter =
      this.props.timescale === Constants.timescale.DECADE
        ? `FILTER("` +
          this.props.startDate +
          `"^^xsd:date <= ?date && ?date <= "` +
          this.props.endDate +
          `"^^xsd:date)`
        : "";

    // For use when weather set to "wet" or "mixed" (to avoid excluding races with no information)
    const wetnessProperty =
      this.props.conditions === Constants.conditions.ALL
        ? ""
        : "; f1:hasWetness ?wetness ";

    const wetnessCondition =
      this.props.conditions === Constants.conditions.ALL
        ? ""
        : `VALUES ?wetness { f1:wetness_Wet  ${
            this.props.conditions === Constants.conditions.MIXED
              ? "f1:wetness_Mixed"
              : ""
          } }.`;

    const calculation =
      this.props.calc === Constants.calc.TOTAL
        ? "?scoreSum"
        : "?scoreSum/?resultCount";

    const seasonProperty =
      this.props.timescale === Constants.timescale.ERA
        ? "; f1:hasSeason ?season "
        : "";
    const eraProperty =
      this.props.timescale === Constants.timescale.ERA
        ? "?season f1:hasEra ?era ."
        : "";

    const eraValues =
      this.props.timescale === Constants.timescale.ERA
        ? "VALUES ?era {" +
          (this.props.eras.length === 0
            ? "f1:None"
            : this.props.eras.join(" ")) +
          " }"
        : "";

    const nationalityValues =
      this.props.nationalities === null
        ? ""
        : `?driver f1:hasNationality ?nationality.

                    VALUES ?nationality { ${
                      this.props.nationalities.length === 0
                        ? "f1:None"
                        : this.props.nationalities
                            .map((nation) => {
                              return '"' + nation + '"';
                            })
                            .join(" ")
                    }} .`;

    const driverSparqlQuery = `prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix f1: <http://www.oxfordsemantic.tech/f1demo/>

# Outer query - calculate result
SELECT ?driver ?finalScore ?forename ?surname ?driverUrl ?nationality ?flagUrl ?photoPath ?resultCount ?podiumCount ?wins ?firstResultDate ?lastResultDate ?constructorList WHERE {
    ?driver f1:hasForename ?forename;
            f1:hasSurname ?surname;
            f1:hasUrl ?driverUrl;
            f1:hasNationality ?nationality;
            f1:hasFlag ?flag.
    OPTIONAL {?driver f1:hasMainPhoto ?photo. ?photo f1:hasLocation ?photoPath .}
  	?flag   f1:hasImageUrl ?flagUrl.
    #?constructor f1:hasName ?constructorName.
    
            ${podiumFilter}
            ${minRacesFilter}

     {
        SELECT ?driver (SUM(?score) AS ?scoreSum) (COUNT(?result) AS ?resultCount) (MIN(?date) AS ?firstResultDate) (MAX(?date) AS ?lastResultDate) (SUM(?podium) AS ?podiumCount) (SUM(?win) AS ?wins) (GROUP_CONCAT(DISTINCT CONCAT( ?constructorName,", ")) AS ?constructorList) WHERE {
            ?driver a f1:Driver .
            #{
                #SELECT DISTINCT ?driver ?result ?score ?date WHERE {
                    ?result a f1:PrimaryResult;
                        f1:hasPosition ?position;
                        f1:hasConstructor ?constructor;
                        f1:hasDriver ?driver;
                        ${
                          // RACEPOINTS or POSITIONSGAINED
                          this.props.scoring === Constants.scoring.RACEPOINTS
                            ? "f1:hasModernPoints ?points ;"
                            : "f1:hasGridImprovement ?gridImprovement ;"
                        }${
      // Only use for RACE POINTS + TEAMMATE
      this.props.scoring === Constants.scoring.RACEPOINTS &&
      this.props.field === Constants.field.TEAMMATES
        ? "              f1:hasBestTeammatePoints ?bestTeammatePoints ;"
        : ""
    }${
      // Only use for POSITION IMPROVEMENT + TEAMMATE
      this.props.scoring === Constants.scoring.POSITIONSGAINED &&
      this.props.field === Constants.field.TEAMMATES
        ? "              f1:hasBestTeammateGridImprovement ?bestTeammateGridImprovement ;"
        : ""
    }
                        f1:hasRace ?race .
                    ?race f1:hasDate ?date ${seasonProperty}${wetnessProperty} .
                    ?constructor f1:hasName ?constructorName .
                    
                    ${eraProperty}
                    ${dateFilter}
                    ${wetnessCondition}
                    ${eraValues}
                    ${nationalityValues}

                    BIND(IF(EXISTS{?result a f1:PodiumResult},1,0) AS ?podium).
                    BIND(IF(?position=1,1,0) AS ?win).
                    BIND(
                        ${
                          // Race Points & Whole Field
                          this.props.scoring === Constants.scoring.RACEPOINTS &&
                          this.props.field === Constants.field.WHOLEFIELD
                            ? "?points"
                            : ""
                        }${
      // Race Points & Whole Field
      this.props.scoring === Constants.scoring.POSITIONSGAINED &&
      this.props.field === Constants.field.WHOLEFIELD
        ? "?gridImprovement"
        : ""
    }${
      // Race Points & Team mates
      this.props.scoring === Constants.scoring.RACEPOINTS &&
      this.props.field === Constants.field.TEAMMATES
        ? "?points - ?bestTeammatePoints"
        : ""
    }${
      // Race Points & Team mates
      this.props.scoring === Constants.scoring.POSITIONSGAINED &&
      this.props.field === Constants.field.TEAMMATES
        ? "?gridImprovement - ?bestTeammateGridImprovement"
        : ""
    } AS ?score )
              #  }
            #}
        } GROUP BY ?driver
    } .
    BIND(
      ${calculation}
        AS ?finalScore
    )
} GROUP BY(?driver) ORDER BY DESC(?finalScore) LIMIT 20`;

    if (driverSparqlQuery === this.state.lastSparqlString) {
      //console.log("no need to fetch");
      return;
    }
    this.setState({
      //isFetching: true,
      lastSparqlString: driverSparqlQuery,
    });

    let headers = new Headers();
    headers.append("Accept", "application/x.sparql-results+json-abbrev");
    const url = Constants.RDFoxURL;
    //console.log("About to fetch");

    if (Constants.PrefetchResults) {
      fetch(
        url +
          "?query=" +
          encodeURIComponent(driverSparqlQuery).replace(/%20/g, "+"),
        {
          headers: headers,
          mode: "cors",
        }
      );
      var start = new Date().getTime();
      for (var i = 0; i < 1e7; i++) {
        if (new Date().getTime() - start > 200) {
          break;
        }
      }
    }

    const startTime = performance.now();
    fetch(
      url +
        "?query=" +
        encodeURIComponent(driverSparqlQuery).replace(/%20/g, "+"),
      {
        headers: headers,
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((jsonResponce) => {
        //console.log("response ", jsonResponce);
        const endTime = performance.now();
        let pos = 1;
        const driverData = jsonResponce.results.bindings.map((row) => {
          // SELECT ?driver ?finalScore ?forename ?surname ?resultCount ?lastResultDate
          return {
            position: pos++,
            iri: row.driver.value,
            label:
              row.forename.value.substring(0, 1) +
              row.surname.value.substring(0, 1),
            name: row.forename.value + " " + row.surname.value,
            driverUrl: row.driverUrl.value,
            races: row.resultCount.value,
            y: parseFloat(row.finalScore.value),
            x: parseInt(row.lastResultDate.value.substring(0, 4)),
            wins: parseInt(row.wins.value),
            podiums: parseInt(row.podiumCount.value),
            firstRaceDate: row.firstResultDate.value,
            lastRaceDate: row.lastResultDate.value,
            nationality: row.nationality.value,
            img: row.photoPath?.value,
            flagUrl: row.flagUrl.value,
            constructorList: row.constructorList.value.substring(
              0,
              row.constructorList.value.length - 2
            ),
            r: 10,
          };
        });
        //console.log("mapped data ", driverData);
        const errorMessage =
          jsonResponce.results.bindings.length > 0
            ? ""
            : "No drivers fit this criteria.";
        this.setState({
          data: driverData,
          errorMessage: errorMessage,
          isFetching: false,
          timeTaken: Math.round(endTime - startTime),
        });
      })
      .catch((e) => {
        console.log("Error calling RDFox: ", e);
        this.setState({
          data: null,
          errorMessage: "Error fetching data.",
          isFetching: false,
        });
      });
  };

  // _setRef = (c) => {
  //   this._chart = c;
  // };

  render() {
    //console.log("BubbleChart render", this.state, this.props);

    const driverIcon = window.innerWidth <= 760 ? driverIcon24 : driverIcon36;

    const data = {
      labels: ["Red"],
      datasets: [
        {
          labels: ["Drivers"],
          data: this.state.data,
          backgroundColor: "rgba(255, 100, 100,0.5)",
          borderColor: "rgb(255, 100, 100)",
          borderWidth: 2,
          pointStyle: driverIcon,
        },
      ],
    };

    const options = {
      animation: false,
      layout: {
        padding: 40,
      },
      indexAxis: "score",
      scales: {
        x: {
          ticks: {
            callback: function (value, index, values) {
              return value;
            },
          },
        },
        // y: {
        //   min: 0,
        // },
      },
      responsive: true,
      onClick: function (ev, element) {
        //console.log("Click detected ", ev, element);
        if (element.length > 0) {
          console.log(
            element[0].datasetIndex,
            element[0].index,
            ev.chart.data.datasets[element[0].datasetIndex].data[
              element[0].index
            ].iri
          );
        }
      },

      maintainAspectRatio: false,

      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: true,
          //mode: "x",
          intersect: true,
          usePointStyle: false,
          titleFont: { size: 16 },
          callbacks: {
            title: function (context) {
              //console.log("in title function", context);
              return context[0].raw.name;
            },
            beforeBody: function (context) {
              const driver = context[0].raw;
              return driver.y.toFixed(2) + " score\n" + driver.races + " races";
            },
            label: function (context) {
              //console.log("in label function", context);
              return "";
            },
          },
        },

        datalabels: {
          font: { size: 16 },
          display: true,
          align: "right",
          offset: 15,
          color: "grey",
          textShadowColor: "Black",
          // textStrokeWidth:1,
          textShadowBlur: 5,
          formatter: function (value) {
            return value.label;
          },
        },
      },
    };

    return (
      <Grid container spacing={2}>
        {Constants.DisplayResponseTimes ? (
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ padding: 1 }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                //spacing={2}
              >
                <Typography
                  variant="h5"
                  //color="primary"
                  display="inline"
                  //border={1}
                >
                  From <b>question</b> to <b>answer</b> in{" "}
                </Typography>
                <Typography
                  variant="h4"
                  color="primary"
                  display="inline"
                  padding={1}
                >
                  <b>{this.state.timeTaken}</b>
                </Typography>{" "}
                <Typography
                  variant="h5"
                  //color="primary"
                  display="inline"
                  //gutterBottom
                  //border={1}
                >
                  milliseconds with{" "}
                  <Link href="https://oxfordsemantic.tech/" underline="hover">
                    <Typography variant="h6" color="primary" display="inline">
                      RDFox
                    </Typography>
                  </Link>
                  .
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 1 }}>
            <Grid
              sx={{
                position: "relative",
                height: "500px",
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
              }}
              //border={1}
            >
              {Constants.DisplayResponseTimes ? (
                <Grid
                  alignItems="right"
                  sx={{
                    position: "absolute",
                    bottom: -30,
                    right: -8,
                    //width: "100%",
                  }}
                >
                  <Typography
                    variant="caption"
                    color="primary"
                    display="block"
                    gutterBottom
                  >
                    Results in {this.state.timeTaken} ms.
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
              {this.state.isFetching ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {this.state.data && this.state.data.length > 0 ? (
                    <Bubble
                      //height={100}
                      plugins={[ChartDataLabels]}
                      data={data}
                      options={options}
                      //ref={this.myRef}
                      //ref={this._setRef}
                    />
                  ) : (
                    <Grid container justifyContent="center" alignItems="center">
                      <Alert severity="warning">
                        {this.state.errorMessage}
                      </Alert>
                    </Grid>
                  )}
                </>
              )}

              {/* {this.state.showTooltip ? ( */}
              {/* <Tooltip style={{ top: this.state.top, left: this.state.left }}>
          <div>Date: {this.state.date}</div>
          <div>Value: {this.state.value}</div>
        </Tooltip> */}
              {/* ) : null} */}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {this.state.data && this.state.data.length > 0 ? (
            <DriverDetail drivers={this.state.data} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default DriversView;
