import * as React from "react";
import Paper from "@mui/material/Paper";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

class NationalitySelector extends React.Component {
  render() {
    // console.log(
    //   " render for NationalitySelector ",
    //   this.props.possibleNationalities,
    //   this.props.nationalitiesSelected
    // );
    return (
      <Paper elevation={1} sx={{ padding: 2, boxShadow: 0, pt: 0, mt: 1 }}>
        <ListItem
          key="all"
          secondaryAction={
            <Checkbox
              edge="end"
              onChange={this.props.toggleNationalityItem("all")}
              checked={
                this.props.possibleNationalities.length ===
                this.props.nationalitiesSelected.length
              }
            />
          }
        >
          <ListItemText id="all" primary="All" />
        </ListItem>
        <Divider />
        <List sx={{ maxHeight: 200, overflow: "auto" }}>
          {this.props.possibleNationalities.map((nation) => {
            const labelId = `nation-checkbox-${nation}`;
            return (
              <ListItem
                key={labelId}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={this.props.toggleNationalityItem(nation)}
                    checked={
                      this.props.nationalitiesSelected.indexOf(nation) !== -1
                    }
                  />
                }
              >
                <ListItemText id={labelId} primary={nation} />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    );
  }
}

export default NationalitySelector;
