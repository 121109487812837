import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import MainDashboard from "./components/MainDashboard";

export default function App() {
  return (
    <Container maxWidth="false" disableGutters={true} id="outercontainer">
      <Box>
        <MainDashboard />
      </Box>
    </Container>
  );
}
