import * as React from "react";

import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { red, purple, green, grey } from "@mui/material/colors";
import { keyframes } from "@mui/system";
import { styled } from "@mui/material/styles";

const appear = keyframes`
  0%   { opacity: 0.5; }
  100% { opacity: 1; }`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 10,
    padding: 4,
    bgcolor: "grey",
  },
}));

function DriverDetail(props) {
  const scoreIntegerLength = parseFloat(props.drivers[0].y).toFixed(0).length;
  const scoreDecimals = scoreIntegerLength < 3 ? 3 - scoreIntegerLength : 0;
  const scoreFontsize = scoreIntegerLength <= 3 ? 24 : 18;
  //   console.log(
  //     "DriverDetail ",
  //     props.drivers,
  //     props.drivers[0].y,
  //     scoreIntegerLength,
  //     scoreDecimals,
  //     scoreFontsize
  //   );
  let row = 0;

  return (
    // <Paper elevation={3} sx={{ padding: 1 }}>
    <TableContainer
      component={Paper}
      elevation={3}
      //sx={{ padding: 1 }}
    >
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow key="header row">
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center">Score</TableCell>
            <TableCell align="center">Wins</TableCell>
            <TableCell align="center">Podiums</TableCell>
            <TableCell>Details from your selection</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.drivers.slice(0, 10).map((driver) => {
            const roundedUpScore = driver.y.toFixed(scoreDecimals);
            const light = row++ % 2 === 0 ? true : false;
            const backgroundColor = light ? grey[900] : "none";
            const positionColor =
              driver.position === 1
                ? purple[300]
                : driver.position === 2
                ? green[400]
                : red[400];
            return [
              <TableRow
                key={"top" + driver.iri}
                sx={{
                  backgroundColor: { backgroundColor },
                }}
              >
                <TableCell rowSpan={2} sx={{ maxWidth: 80 }}>
                  <center>
                    <Avatar
                      sx={{
                        //border: 2,
                        borderColor: "#a5f1e6",
                        width: 70,
                        height: 70,
                        color: "#a5f1e6",
                        bgcolor: grey[800],
                        fontSize: 42,
                      }}
                    >
                      {driver.position}
                    </Avatar>
                  </center>
                </TableCell>
                <TableCell rowSpan={2}>
                  <center>
                    <StyledBadge
                      badgeContent={
                        <>
                          <img
                            height={15}
                            src={driver.flagUrl}
                            alt={driver.nationality}
                          />
                        </>
                      }
                    >
                      <Link color="inherit" href={driver.driverUrl} target="_blank" rel="external noreferrer">
                        <Avatar
                          alt={driver.label}
                          src={driver.img}
                          sx={{
                            width: 90,
                            height: 90,
                            color: "#a5f1e6",
                            bgcolor: grey[800],
                            fontSize: 26,
                          }}
                        >
                          {driver.label}
                        </Avatar>
                      </Link>
                    </StyledBadge>
                  </center>
                </TableCell>

                <TableCell
                  key={driver.name}
                  colSpan={3}
                  sx={{
                    borderBottom: "none",
                    animation: `${appear} .5s ease-in-out  alternate`,
                  }}
                >
                  <Link href={driver.driverUrl} target="_blank" rel="external noreferrer" underline="hover">
                    <Typography
                      color="primary"
                      variant="h6"
                      sx={{ borderBottom: 1 }}
                      lower-border={1}
                    >
                      {driver.name}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell rowSpan={2} sx={{ maxWidth: 200 }}>
                  <Typography
                    color="text.secondary"
                    variant="body"
                    paragraph={false}
                  >
                    Seasons:
                  </Typography>{" "}
                  {driver.firstRaceDate.substring(0, 4)} -{" "}
                  {driver.lastRaceDate.substring(0, 4)}
                  <br />
                  <Typography
                    color="text.secondary"
                    variant="body"
                    paragraph={false}
                  >
                    Races:{" "}
                  </Typography>
                  {driver.races}
                  <br />
                  <Typography
                    color="text.secondary"
                    variant="body"
                    paragraph={false}
                  >
                    Constructors:{" "}
                  </Typography>
                  {driver.constructorList}
                </TableCell>
              </TableRow>,

              <TableRow
                key={"lower" + driver.iri}
                sx={{
                  boxShadow: 0,
                  borderBottom: "none",
                  backgroundColor: { backgroundColor },
                }}
              >
                <TableCell>
                  <center>
                    <Avatar
                      sx={{
                        width: 50,
                        height: 50,
                        bgcolor: positionColor,
                        fontSize: { scoreFontsize },
                        animation: `${appear} .5s ease-in-out  alternate`,
                      }}
                    >
                      {roundedUpScore}
                    </Avatar>
                  </center>
                </TableCell>
                <TableCell>
                  <center>
                    <Avatar
                      sx={{
                        width: 50,
                        height: 50,
                        bgcolor: positionColor,
                        fontSize: 24,
                        animation: `${appear} .5s ease-in-out  alternate`,
                      }}
                    >
                      {driver.wins}
                    </Avatar>
                  </center>
                </TableCell>
                <TableCell>
                  <center>
                    <Avatar
                      sx={{
                        width: 50,
                        height: 50,
                        bgcolor: positionColor,
                        fontSize: 24,
                        animation: `${appear} .5s ease-in-out  alternate`,
                      }}
                    >
                      {driver.podiums}
                    </Avatar>
                  </center>
                </TableCell>
              </TableRow>,
            ];
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DriverDetail;
