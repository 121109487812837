import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#a5f1e6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiToggleButtonBase: {
      defaultProps: { color: "primary" },
      disableRipple: true,
      color: "primary",
    },
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        //disableRipple: true, // No more ripple!
        defaultProps: { color: "red" },
      },
    },
  },
});

theme.typography.h4 = {
  fontSize: "1.0rem",
  "@media (min-width:600px)": {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.6rem",
  },
};

theme.typography.h6 = {
  fontSize: "1.0rem",
  "@media (min-width:600px)": {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
};

export default theme;
