import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Link from "@mui/material/Link";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxHeight: "80%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "5px solid #a5f1e6",
  boxShadow: 24,
  p: 4,
};

function AboutModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Grid container alignItems="center">
        <Grid flex={1} />
        <Grid>
          <IconButton onClick={handleOpen}>
            <InfoOutlinedIcon color="disabled" />
          </IconButton>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{ mt: 1 }}
          >
            About this Application
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            This application translates your driver scoring criteria into a
            query and sends it to an instance of an RDFox database containing
            all Formula 1 information from accross the years. To findout more
            about RDFox please vist our{" "}
            <Link href="https://www.oxfordsemantic.tech/product">
              product page
            </Link>
            .
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 3 }}
          >
            Scoring
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <b>Race points:</b> for each result, the driver receives the number
            of points corresponding to their position according to the modern
            Formula 1 scoring system (25, 18, 15...). Note no points are awarded
            for fastest lap or qualifying results. <p />
            <b>Positions gained:</b> for each result, the number of points
            awarded is equal to the non-negative difference between the driver’s
            finishing position and their starting grid position
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 3 }}
            >
              Field selection
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1 }}>
              <b>Whole field: </b> for each result, the points are awarded as
              indicated by the scoring option selected.
              <p />
              <b>Team mates: </b> for each result, the points awarded are the
              difference between the driver’s points according to the scoring
              system selected and the points of their best team mate.
            </Typography>
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 3 }}
          >
            Calculation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <b>Total: </b> the driver’s final score is the sum of all the scores
            they obtained from races that match your filters.
            <p />
            <b>Average:</b> the driver’s final score is the average score they
            obtained in races that match your filters.
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 3 }}
          >
            Conditions
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            Weather information for each race has been classified into one of
            three categories: “dry”, “mixed” (half dry, half wet) and “wet”.
            <p />
            <b>All:</b> include all results, even if weather information is not
            available. <p />
            <b>Mixed:</b> include only results with weather information from the
            “mixed” and “wet” categories.
            <p />
            <b>Wet: </b> include only results with weather information from the
            “wet” category.
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 3 }}
          >
            Minimum races
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            The minimum number of races that match your filters that the driver
            has to have participated in in order to be considered in the
            ranking.
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 3 }}
          >
            Minimum podiums
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            The minimum number of races that match your filters that the driver
            has to have achieved a podium result in in order to be considered
            for the ranking.
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 3 }}
          >
            Timescale
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <b>All:</b> include all results.
            <p />
            <b>Decade:</b> include only results from selected decades.
            <p />
            <b>Era:</b> include only results from selected eras of Formula 1
            history.
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 3 }}
          >
            Nationality
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <b>All:</b> include all drivers’ results.
            <p />
            <b>Select: </b> include only results of drivers of selected
            nationalities.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default AboutModal;
