export const scoring = {
  RACEPOINTS: "Race points",
  POSITIONSGAINED: "positions gained",
};

export const RDFoxURL = "/datastores/default/sparql";
//export const RDFoxURL = "http://192.168.1.161:12110/datastores/default/sparql";
export const PrefetchResults = false;
export const DisplayResponseTimes = false;

export const field = { WHOLEFIELD: "whole field", TEAMMATES: "team mates" };
export const calc = { TOTAL: "total", AVERAGE: "average" };
export const conditions = { ALL: "all", MIXED: "mixed", WET: "wet" };
export const timescale = { ALL: "all", DECADE: "decade", ERA: "era" };
export const nationality = { ALL: "all", NATIONALITY: "select" };

export const eraList = [
  { iri: "f1:era_14", name: "Ground Effect Return", years: "2022-present" },
  { iri: "f1:era_13", name: "Hybrid V6 Turbocharged", years: "2014-2021" },
  { iri: "f1:era_12", name: "KERS Hybrid V8", years: "2009-2013" },
  { iri: "f1:era_11", name: "V8 Bridgestone Takeover", years: "2006-2008" },
  { iri: "f1:era_10", name: "V10 Road Car Rise", years: "2000-2005" },
  { iri: "f1:era_9", name: "3L Downgrade", years: "1995-1999" },
  { iri: "f1:era_8", name: "3.5L Naturally-Aspirated", years: "1989-1994" },
  { iri: "f1:era_7", name: "1.5L Turbocharged", years: "1983-1988" },
  { iri: "f1:era_6", name: "Ground Effect", years: "1977-1982" },
  { iri: "f1:era_5", name: "12-Cylinder DFV Adoption", years: "1968-1976" },
  { iri: "f1:era_4", name: "Anglophone 1.5L Mid", years: "1962-1967" },
  { iri: "f1:era_3", name: "Rear-Mid Revolution", years: "1958-1961" },
  { iri: "f1:era_2", name: "2.5L Front Italian Dominio", years: "1951-1957" },
  { iri: "f1:era_1", name: "Pre-War Supercharged", years: "1946-1950" },
];

export const minYear = 1950;
export const maxYear = 2030;
export const minDecadeSliderDistance = 10;
