import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AboutModal from "./AboutModal";

function F1ToggleGroup(props) {
  return (
    <>
      <Grid container alignItems="center">
        <Grid flex={1}>
          {props.aboutModal ? (
            <Grid container alignItems="flex-end" justifyContent="space-between">
              <Grid item>
                <Typography color="text.secondary">{props.heading}</Typography>
              </Grid>
              <Grid item>
                <AboutModal />
              </Grid>
            </Grid>
          ) : (
            <Typography color="text.secondary">{props.heading}</Typography>
          )}
        </Grid>
        <Grid>
          {props.tooltip ? (
            <Tooltip title={props.tooltip} placement="right">
              <IconButton>
                <InfoOutlinedIcon color="disabled" />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ pb: 2 }}>
        <ToggleButtonGroup
          color="primary"
          value={props.selection}
          exclusive
          fullWidth={true} //{props.width === "fullwidth" ? true : false}
          onChange={props.handleToggle}
        >
          {props.options.map((option) => {
            return (
              <ToggleButton
                key={option}
                value={option}
                sx={{
                  borderRadius: 6,

                  width: null, // props.width === "fullwidth" ? null : props.width,
                }}
              >
                {option}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Grid>
    </>
  );
}

export default F1ToggleGroup;
