import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Collapse from "@mui/material/Collapse";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

import * as Constants from "./constants";
import F1ToggleGroup from "./F1ToggleGroup";
import NationalitySelector from "./NationalitySelector";

function Menu(
  scoring,
  handleScoringToggle,
  field,
  handleField,
  calc,
  handleCalc,
  conditions,
  handleConditions,
  minRaces,
  setMinRaces,
  minPodiums,
  setMinPodiums,
  timescale,
  handleTimescale,
  decadeSlider,
  handleDecadeSlider,
  handleEraAllToggle,
  eraChecked,
  handleEraToggle,
  nationality,
  handleNationality,
  possibleNationalities,
  nationalitiesSelected,
  toggleNationalityItem
) {
  //console.log({ eraChecked }, { nationality });
  return (
    <>
      <Toolbar />

      <Paper elevation={3} sx={{ padding: { xs: 1, sm: 2 } }}>
        {
          // =========== SCORING ===========
        }
        <F1ToggleGroup
          heading="Scoring"
          selection={scoring}
          handleToggle={handleScoringToggle}
          options={[
            Constants.scoring.RACEPOINTS,
            Constants.scoring.POSITIONSGAINED,
          ]}
          width="fullwidth"
          aboutModal={true}
          //tooltip="Scoring tooltip" //TODO
        />
        {
          //  ===========     FIELD     ===========
        }
        <F1ToggleGroup
          heading="Field selection"
          selection={field}
          //tooltip="Field selection tooltip" // TODO
          handleToggle={handleField}
          options={[Constants.field.WHOLEFIELD, Constants.field.TEAMMATES]}
          width={130}
        />
        {
          // ===========     CALCULATION     ===========
        }
        <F1ToggleGroup
          heading="Calculation"
          selection={calc}
          //tooltip="Calculation scheme tooltip" // TODO
          handleToggle={handleCalc}
          options={[Constants.calc.TOTAL, Constants.calc.AVERAGE]}
          width={90}
        />
        {
          // ===========     WEATHER CONDITIONS     ===========
        }
        <F1ToggleGroup
          heading="Conditions"
          selection={conditions}
          //tooltip="Conditions selection" // TODO
          handleToggle={handleConditions}
          options={[
            Constants.conditions.ALL,
            Constants.conditions.MIXED,
            Constants.conditions.WET,
          ]}
          width={-1}
        />

        {
          // ===========     MIN RACES     ===========
        }
        <Grid container sx={{ pt: 2 }}>
          <Grid item xs={6}>
            <center>
              <Typography color="text.secondary" sx={{ pb: 0.5 }}>
                Minimum races
              </Typography>
            </center>
            <Grid container justifyContent="center">
              <div className="TextField-with-border-radius">
                <TextField
                  type="number"
                  value={minRaces}
                  onChange={(ev) => {
                    //console.log("min Races set ", ev.target.value);
                    setMinRaces(parseInt(ev.target.value, 10));
                  }}
                  inputProps={{
                    min: 1,
                    max: 100,
                    style: { textAlign: "right" },
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <center>
              <Typography color="text.secondary" sx={{ pb: 0.5 }}>
                Minimum podiums
              </Typography>
            </center>
            <Grid container justifyContent="center">
              <div className="TextField-with-border-radius">
                <TextField
                  type="number"
                  value={minPodiums}
                  onChange={(ev) => {
                    //console.log("min Races set ", ev.target.value);
                    setMinPodiums(parseInt(ev.target.value, 10));
                  }}
                  inputProps={{
                    min: 0,
                    max: 100,
                    style: { textAlign: "right" },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={3} sx={{ mt: 2, padding: 2 }}>
        {
          // ===========     TIMESCALE     ===========
        }
        <F1ToggleGroup
          heading="Timescale"
          selection={timescale}
          //tooltip="Time range selection" // TODO
          handleToggle={handleTimescale}
          options={[
            Constants.timescale.ALL,
            Constants.timescale.DECADE,
            Constants.timescale.ERA,
          ]}
          width={-1}
        />
        {
          //                 DECADE     ===========
        }
        <Collapse in={timescale === Constants.timescale.DECADE}>
          <Paper elevation={1} sx={{ padding: 3, boxShadow: 0, pt: 3, mt: 1 }}>
            <Slider
              getAriaLabel={() => "Minimum distance shift"}
              value={decadeSlider}
              marks={[
                { value: 1950, label: "1950" },
                { value: 1980, label: "1980" },
                { value: 2000, label: "2000" },
                { value: 2030, label: "2030" },
              ]}
              min={Constants.minYear}
              max={Constants.maxYear}
              step={Constants.minDecadeSliderDistance}
              onChange={handleDecadeSlider}
              valueLabelDisplay="auto"
              disableSwap
            />
          </Paper>
        </Collapse>
        {
          //                 ERA     ===========
        }

        <Collapse in={timescale === Constants.timescale.ERA}>
          <Paper elevation={1} sx={{ padding: 2, boxShadow: 0, pt: 0, mt: 1 }}>
            <ListItem
              key="all"
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleEraAllToggle}
                  checked={eraChecked.length === Constants.eraList.length}
                />
              }
            >
              <ListItemText id="all" primary="All" />
            </ListItem>
            <Divider />
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {Constants.eraList.map((era) => {
                const labelId = `checkbox-list-secondary-label-${era.iri}`;
                return (
                  <ListItem
                    key={labelId}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleEraToggle(era.iri)}
                        checked={eraChecked.indexOf(era.iri) !== -1}
                      />
                    }
                  >
                    <Tooltip
                      title={"Era for the years " + era.years}
                      placement="bottom"
                    >
                      <ListItemText id={labelId} primary={era.name} />
                    </Tooltip>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Collapse>
        {
          // ===========     NATIONALITY     ===========
        }
        <F1ToggleGroup
          heading="Nationality"
          selection={nationality}
          //tooltip="Time range selection" // TODO
          handleToggle={handleNationality}
          options={[
            Constants.nationality.ALL,
            Constants.nationality.NATIONALITY,
          ]}
          width={-1}
        />

        <Collapse in={nationality === Constants.nationality.NATIONALITY}>
          <NationalitySelector
            possibleNationalities={possibleNationalities}
            nationalitiesSelected={nationalitiesSelected}
            toggleNationalityItem={toggleNationalityItem}
          />
        </Collapse>
      </Paper>
    </>
  );
}

export default Menu;
