import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { Doughnut } from "react-chartjs-2";
import { keyframes } from "@mui/system";
import * as Constants from "./constants";

const glow = keyframes`
  0% {}
  50% {text-shadow: 0 0 2px #fff, 0 0 5px #555;}
  100% {}`;

function InfoBox(props) {
  const options = {
    plugins: {
      legend: { display: false },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const mainNeedleThickness = props.total / 40;
  const smallNeedleThickness = mainNeedleThickness * 0.5;

  const data = {
    labels: ["outer needle", "count", "needle", "the rest"],
    datasets: [
      {
        label: "outer",
        data: [props.value, mainNeedleThickness, props.total - props.value],
        backgroundColor: [
          "rgba(0, 0, 0, 0.2)",
          "rgba(255, 0, 0, .8)",
          "rgba(0, 0, 0, 0.2)",
        ],
        borderWidth: 0,
        cutout: "50%",
        circumference: 220,
        rotation: -110,
        weight: 0.2,
      },
      {
        label: "main",
        data: [props.value, mainNeedleThickness, props.total - props.value], // An additional datapoint is used to draw the red needle
        backgroundColor: ["#a5f1e6", "red", "rgba(255, 255, 255, 0.2)"],
        borderWidth: 0,
        cutout: "50%",
        circumference: 220,
        rotation: -110,
        weight: 1.0,
      },
      {
        label: "dial",
        data: [props.value, mainNeedleThickness, props.total - props.value],
        backgroundColor: [
          "rgb(165,241,230,.5)",
          "rgba(255, 0, 0, .8)",
          "rgba(0, 0, 0, 0.2)",
        ],
        borderWidth: 0,
        cutout: "55%",
        circumference: 220,
        rotation: -110,
        weight: 0.5,
      },
      {
        label: "needle only",
        data: [
          (mainNeedleThickness - smallNeedleThickness) / 2,
          props.value, //+ mainNeedleThickness * 0.25,
          smallNeedleThickness,
          props.total - props.value, //+ mainNeedleThickness * 0.25,
          (mainNeedleThickness - smallNeedleThickness) / 2,
        ],
        backgroundColor: [
          "rgba(255, 255, 255, 0.2)",
          "rgba(255, 255, 255, 0)",
          "rgba(255, 0, 0, 0.7)",
          "rgba(255, 255, 255, 0)",
          "rgba(255, 255, 255, 0.2)",
        ],
        borderWidth: 0,
        cutout: "50%",
        circumference: 220,
        rotation: -110,
        weight: 2,
      },
    ],
  };
  return (
    <Grid item xs={4} md={4} lg={12}>
      <Paper elevation={3} sx={{ p: 1 }}>
        <Typography
          variant="body1"
          align="left"
          color="text.secondary"
          // sx={{ pb: 2 }}
        >
          {props.title}
        </Typography>
        <Grid position="relative" height={70}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "absolute",
              //border: 1,
              top: -19,
              width: "100%",
            }}
          >
            <Grid
              item
              //border={1}
              sx={{
                width: "80pt",
              }}
            >
              <Doughnut data={data} options={options} />
            </Grid>
          </Grid>

          <Typography
            key={"glow_" + props.value}
            sx={{
              pt: "22pt",
              animation: `${glow} .25s ease-in-out  alternate`,
            }}
            variant={props.value > 999 ? "h5" : "h4"}
            align="center"
            color="primary"
          >
            {props.value}
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Grid
            container
            //border={1}
            justifyContent="space-between"
            width="80pt"
          >
            <Typography variant="body2" color="text.secondary">
              0
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.total}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

function InfoBoxes(props) {
  //console.log(props);

  if (props.totals?.errorMessage) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Alert severity="warning">{props.totals.errorMessage}</Alert>
      </Grid>
    );
  }
  if (props.data && props.totals) {
    return (
      <Grid container spacing={2} sx={{ position: "relative" }}>
        {Constants.DisplayResponseTimes ? (
          <Grid
            alignItems="right"
            sx={{
              position: "absolute",
              bottom: -22,
              right: 0,
              //width: "100%",
            }}
          >
            <Typography
              variant="caption"
              color="primary"
              display="block"
              gutterBottom
            >
              Stats in {props.data.timeTaken} ms.
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
        <InfoBox
          title="Races"
          value={props.data.raceCount}
          total={props.totals.raceCount}
        />
        <InfoBox
          title="Drivers"
          value={props.data.driverCount}
          total={props.totals.driverCount}
        />
        <InfoBox
          title="Driver results"
          value={props.data.resultCount}
          total={props.totals.resultCount}
        />
        <InfoBox
          title="Seasons"
          value={props.data.seasonCount}
          total={props.totals.seasonCount}
        />
        <InfoBox
          title="Constructors"
          value={props.data.constructorCount}
          total={props.totals.constructorCount}
        />
        <InfoBox
          title="Circuits"
          value={props.data.circuitCount}
          total={props.totals.circuitCount}
        />
      </Grid>
    );
  } else {
    return <></>;
  }
}

export default InfoBoxes;
