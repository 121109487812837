import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import { ThemeProvider } from "@mui/material/styles";

import DriversView from "./DriversView";
import theme from "../theme";
import Menu from "./Menu";
import InfoBoxes from "./InfoBoxes";

import * as Constants from "./constants";
import * as RDFoxQueries from "./RDFoxQueries";

const drawerWidth = 376;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "5px solid #a5f1e6",
  boxShadow: 24,
  p: 4,
};

class MainDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
      scoring: Constants.scoring.RACEPOINTS,
      field: Constants.field.WHOLEFIELD,
      calc: Constants.calc.TOTAL,
      conditions: Constants.conditions.ALL,
      timescale: Constants.timescale.ALL,
      minRaces: 1,
      minPodiums: 0,
      decadeSlider: [Constants.minYear, Constants.maxYear],
      eras: Constants.eraList.map((era) => {
        return era.iri;
      }),
      nationality: Constants.nationality.ALL,
      possibleNationalities: [],
      nationalitiesSelected: [],

      infoData: null,
      infoQueryString: "",
    };
  }

  toggleNationalityItem = (item) => () => {
    //console.log(this.state, item, this.state.nationalitiesSelected);
    if (item === "all") {
      this.state.nationalitiesSelected.length ===
      this.state.possibleNationalities.length
        ? this.setState({
            nationalitiesSelected: [],
          })
        : this.setState({
            nationalitiesSelected: this.state.possibleNationalities,
          });
      return;
    }

    const index = this.state.nationalitiesSelected.indexOf(item);
    let newSelection = [...this.state.nationalitiesSelected];
    if (index >= 0) {
      // item is in the list remove it
      newSelection.splice(index, 1);
    } else {
      newSelection.push(item);
    }
    this.setState({
      nationalitiesSelected: newSelection,
    });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleScoringToggle = (event, newField) => {
    if (newField !== null) {
      this.setState({ scoring: newField });
    }
  };

  handleField = (event, newField) => {
    if (newField !== null) {
      this.setState({ field: newField });
    }
  };

  handleCalc = (event, newField) => {
    if (newField !== null) {
      this.setState({ calc: newField });
    }
  };
  handleConditions = (event, newField) => {
    if (newField !== null) {
      this.setState({ conditions: newField });
    }
  };

  handleTimescale = (event, newField) => {
    if (newField !== null) {
      this.setState({ timescale: newField });
    }
  };
  handleNationality = (event, newField) => {
    if (newField !== null) {
      this.setState({ nationality: newField });
    }
  };
  updateNationalities = (newNationalities) => {
    this.setState({ nationalities: newNationalities });
  };

  handleDecadeSlider = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (
      newValue[0] === this.state.decadeSlider[0] &&
      newValue[1] === this.state.decadeSlider[1]
    )
      return; // no change
    if (newValue[1] - newValue[0] < Constants.minDecadeSliderDistance) {
      //console.debug("Thumb: " + activeThumb);
      if (activeThumb === 0) {
        const clamped = Math.min(
          newValue[0],
          Constants.maxYear - Constants.minDecadeSliderDistance
        );
        this.setState({
          decadeSlider: [clamped, clamped + Constants.minDecadeSliderDistance],
        });
      } else {
        const clamped = Math.max(
          newValue[1],
          Constants.minDecadeSliderDistance + Constants.minYear
        );
        this.setState({
          decadeSlider: [clamped - Constants.minDecadeSliderDistance, clamped],
        });
      }
    } else {
      this.setState({ decadeSlider: newValue });
    }
  };

  handleEraAllToggle = () => {
    if (this.state.eras.length === Constants.eraList.length) {
      this.setState({ eras: [] });
    } else {
      this.setState({
        eras: Constants.eraList.map((era) => {
          return era.iri;
        }),
      });
    }
  };

  handleEraToggle = (value) => () => {
    const currentIndex = this.state.eras.indexOf(value);
    let newChecked = [...this.state.eras];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ eras: newChecked });
  };

  componentDidMount() {
    this.updateInfoTotals();
    this.updateInfo();
    this.fetchNationalities();
  }
  componentDidUpdate() {
    this.updateInfo();
  }

  updateInfoTotals = () => {
    const infoTotalsQueryString = RDFoxQueries.getInfoTotalsQuery();
    RDFoxQueries.fetchInfoData(infoTotalsQueryString, (data) => {
      this.setState({ infoTotals: data });
    });
  };
  fetchNationalities = () => {
    RDFoxQueries.getNationalities((values) => {
      this.setState({
        possibleNationalities: values,
        nationalitiesSelected: values,
      });
    });
  };

  updateInfo = () => {
    const newInfoQueryString = RDFoxQueries.getInfoDataQuery(
      this.state.scoring,
      this.state.field,
      this.state.calc,
      this.state.conditions,
      this.state.minRaces,
      this.state.minPodiums,
      this.state.timescale,
      this.state.decadeSlider[0] + "-01-01",
      this.state.decadeSlider[1] + "-12-25",
      this.state.eras,
      this.state.nationality === Constants.nationality.NATIONALITY
        ? this.state.nationalitiesSelected
        : null
    );
    if (this.state.infoQueryString === newInfoQueryString) {
      return;
    }
    this.setState({ infoQueryString: newInfoQueryString });

    RDFoxQueries.fetchInfoData(newInfoQueryString, (data) => {
      this.setState({ infoData: data });
    });
  };

  render() {
    //console.log(this.state);
    const { window } = this.props;
    const container =
      window !== undefined ? () => window().document.body : undefined;
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          {/* <CssBaseline /> */}
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              //  width: { sm: `calc(100% - ${drawerWidth}px)` },
              //    ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }} // << sm:'none' === not on mobile
              >
                <MenuIcon />
              </IconButton>
              <Grid container columns={12} alignItems="center">
                <Grid
                  item
                  md={2}
                  lg={3}
                  sx={{
                    display: { xs: "none", lg: "block" },
                  }}
                ></Grid>
                <Grid //xs={8}
                  flex={1}
                >
                  <center>
                    <Typography variant="h4" component="div">
                      How would you define the <b>best F1 driver</b>?
                    </Typography>
                  </center>
                </Grid>

                <Grid item md={2} lg={3} sx={{ lg: { minWidth: 250 } }}>
                  <Grid
                    container
                    //border={1}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ pt: 2, pb: 2 }}
                  >
                    <Grid
                      item
                      sx={{
                        display: { xs: "none", lg: "block" },
                      }}
                    >
                      <Typography color="primary" variant="body2">
                        powered by
                      </Typography>
                    </Grid>
                    <Grid
                      //border={1}
                      sx={{
                        pl: 0.5,
                        pt: 1,

                        display: { xs: "none", md: "block" },
                      }}
                    >
                      <Link color="inherit" href="https://oxfordsemantic.tech/">
                        <img
                          alt="RDFox logo"
                          src="RDFOXFinalConsolePath.svg"
                          width="150"
                        />
                      </Link>
                    </Grid>
                    <Grid
                      sx={{
                        pl: 1,
                        //pt: 1,
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      <Link color="inherit" href="https://oxfordsemantic.tech/">
                        <img
                          alt="RDFox logo"
                          src="OSTLogoOnlyConsoleThick.svg"
                          width="50"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
            //border={1}
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", md: "none" }, // << sm:'none' means this does not show on mobile
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  pt: 4,
                  width: { xs: 320, sm: drawerWidth },
                },
              }}
            >
              {Menu(
                this.state.scoring,
                this.handleScoringToggle,
                this.state.field,
                this.handleField,
                this.state.calc,
                this.handleCalc,
                this.state.conditions,
                this.handleConditions,
                this.state.minRaces,
                (value) => {
                  this.setState({ minRaces: value });
                },
                this.state.minPodiums,
                (value) => {
                  this.setState({ minPodiums: value });
                },
                this.state.timescale,
                this.handleTimescale,
                this.state.decadeSlider,
                this.handleDecadeSlider,
                this.handleEraAllToggle,
                this.state.eras,
                this.handleEraToggle,
                this.state.nationality,
                this.handleNationality,
                this.state.possibleNationalities,
                this.state.nationalitiesSelected,
                this.toggleNationalityItem
              )}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", md: "block" }, // << xs:'none' means this does not show on widths > mobile
                "& .MuiDrawer-paper": {
                  //boxSizing: 'border-box',
                  border: 0,
                  pt: 6,
                  pr: 1,
                  width: drawerWidth,
                },
              }}
              open
            >
              {Menu(
                this.state.scoring,
                this.handleScoringToggle,
                this.state.field,
                this.handleField,
                this.state.calc,
                this.handleCalc,
                this.state.conditions,
                this.handleConditions,
                this.state.minRaces,
                (value) => {
                  this.setState({ minRaces: value });
                },
                this.state.minPodiums,
                (value) => {
                  this.setState({ minPodiums: value });
                },
                this.state.timescale,
                this.handleTimescale,
                this.state.decadeSlider,
                this.handleDecadeSlider,
                this.handleEraAllToggle,
                this.state.eras,
                this.handleEraToggle,
                this.state.nationality,
                this.handleNationality,
                this.state.possibleNationalities,
                this.state.nationalitiesSelected,
                this.toggleNationalityItem
              )}
            </Drawer>
          </Box>
          <Box
            id="outerbox"
            container
            component="main"
            //border={1}
            sx={{
              flexCollapse: 1,
              p: 1,
              width: {
                xs: `max(100%,500px)`,
                md: `calc(100% - ${drawerWidth}px)`,
              },
            }}
          >
            <Toolbar />

            <Box sx={{ flexGrow: 1 }} pt={5}>
              <Grid container spacing={1}>
                {this.state.infoTotals?.errorMessage ? (
                  <Modal
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ border: 0 }}
                  >
                    <Box sx={modalStyle}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        There seems to be a problem accessing the data store.
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        We are working to get this fixed and the application
                        should be back with you soon.
                      </Typography>
                    </Box>
                  </Modal>
                ) : (
                  <>
                    <Grid item xs={12} lg={9} xl={10} padding={1}>
                      <DriversView
                        timescale={this.state.timescale}
                        scoring={this.state.scoring}
                        calc={this.state.calc}
                        field={this.state.field}
                        conditions={this.state.conditions}
                        startDate={this.state.decadeSlider[0] + "-01-01"}
                        endDate={this.state.decadeSlider[1] + "-12-25"}
                        minRaces={this.state.minRaces}
                        minPodiums={this.state.minPodiums}
                        eras={this.state.eras}
                        nationalities={
                          this.state.nationality ===
                          Constants.nationality.NATIONALITY
                            ? this.state.nationalitiesSelected
                            : null
                        }
                      />
                    </Grid>

                    <Grid item xs={12} lg={3} xl={2} padding={1}>
                      <InfoBoxes
                        data={this.state.infoData}
                        totals={this.state.infoTotals}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} lg={9} xl={10}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    {"Copyright © "}
                    <Link
                      color="primary"
                      href="https://oxfordsemantic.tech/"
                      underline="hover"
                    >
                      Oxford Semantic Technologies
                    </Link>{" "}
                    {new Date().getFullYear()}
                    {"."}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}
export default MainDashboard;
