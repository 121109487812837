import * as Constants from "./constants";

let headers = new Headers();
headers.append("accept", "application/sparql-results+json");
headers.append("content-type", "application/sparql-query");

export function getInfoDataQuery(
  scoring,
  field,
  calc,
  conditions,
  minRaces,
  minPodiums,
  timescale,
  startDate,
  endDate,
  eras,
  nationalities
) {
  const podiumFilter =
    minPodiums > 0 ? `FILTER(?podiumCount >= ${minPodiums}) .` : "";

  const minRacesFilter =
    minRaces > 1 ? "FILTER(?resultCount >= " + minRaces + ").\n" : "";

  const dateFilter =
    timescale === Constants.timescale.DECADE
      ? `FILTER("` +
        startDate +
        `"^^xsd:date <= ?date && ?date <= "` +
        endDate +
        `"^^xsd:date)`
      : "";

  // For use when weather set to "wet" or "mixed" (to avoid excluding races with no information)
  const wetnessProperty =
    conditions === Constants.conditions.ALL ? "" : "; f1:hasWetness ?wetness ";

  const wetnessCondition =
    conditions === Constants.conditions.ALL
      ? ""
      : `VALUES ?wetness { f1:wetness_Wet  ${
          conditions === Constants.conditions.MIXED ? "f1:wetness_Mixed" : ""
        } }.`;

  // const calculation =
  //   calc === Constants.calc.TOTAL ? "?scoreSum" : "?scoreSum/?resultCount";

  // const seasonProperty =
  //   timescale === Constants.timescale.ERA ? "; f1:hasSeason ?season " : "";
  const eraProperty =
    timescale === Constants.timescale.ERA ? "?season f1:hasEra ?era ." : "";

  const eraValues =
    timescale === Constants.timescale.ERA
      ? "VALUES ?era {" + eras.join(" ") + " }"
      : "";
  const nationalityValues =
    nationalities === null
      ? ""
      : `?driver f1:hasNationality ?nationality.

        VALUES ?nationality { ${
          nationalities.length === 0
            ? "f1:None"
            : nationalities
                .map((nation) => {
                  return '"' + nation + '"';
                })
                .join(" ")
        }} .`;

  const infoSparqlQuery = `prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix f1: <http://www.oxfordsemantic.tech/f1demo/>

SELECT  (COUNT (DISTINCT ?driver) AS ?driverCount) (COUNT (DISTINCT ?circuit) AS ?circuitCount) (COUNT (DISTINCT ?race) AS ?raceCount) (COUNT (DISTINCT ?season) AS ?seasonCount)  (COUNT(?result) AS ?resultCount) (COUNT (DISTINCT ?constructorThen) AS ?constructorCount)  WHERE {
	?result a f1:PrimaryResult;
      f1:hasConstructor ?constructorThen;
      f1:hasDriver ?driver;
      f1:hasRace ?race .

	?race f1:hasCircuit ?circuit;
      f1:hasDate ?date ;
      f1:hasSeason ?season ${wetnessProperty} .
  ?driver a f1:Driver .
  ${podiumFilter} 
  ${minRacesFilter}

  ${dateFilter}
  ${wetnessCondition}
  {
    SELECT ?driver   (COUNT ( ?race) AS ?raceCount) (COUNT(?result) AS ?resultCount) (SUM(?podium) AS ?podiumCount)   WHERE {
 		    ?driver a f1:Driver .

        ?result a f1:PrimaryResult;
            f1:hasConstructor ?constructorThen;
            f1:hasDriver ?driver;
            f1:hasRace ?race ;
            f1:hasPosition ?position.

        ?race f1:hasCircuit ?circuit;
            f1:hasDate ?date ;
            f1:hasSeason ?season
            ${wetnessProperty} .
        ${eraProperty}

        ${dateFilter}
        ${wetnessCondition}
        ${eraValues}
        ${nationalityValues}
        BIND(IF(EXISTS{?result a f1:PodiumResult},1,0) AS ?podium).
    }  GROUP BY(?driver)
  } 

} LIMIT 1`;

  return infoSparqlQuery;
}

export function getInfoTotalsQuery() {
  const infoSparqlQuery = `prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix f1: <http://www.oxfordsemantic.tech/f1demo/>

# Outer query - calculate result
SELECT  ?driverCount ?circuitCount ?raceCount ?seasonCount ?resultCount ?constructorCount  WHERE {
  ?driver a f1:Driver.
  {
    SELECT (COUNT (DISTINCT ?driver) AS ?driverCount) (COUNT (DISTINCT ?circuit) AS ?circuitCount) (COUNT (DISTINCT ?race) AS ?raceCount) (COUNT (DISTINCT ?season) AS ?seasonCount)  (COUNT(?result) AS ?resultCount) (COUNT (DISTINCT ?constructorThen) AS ?constructorCount)  WHERE {

        ?result a f1:PrimaryResult;
            f1:hasConstructor ?constructorThen;
            f1:hasDriver ?driver;
            f1:hasRace ?race ;
            f1:hasPosition ?position.

        ?race f1:hasCircuit ?circuit;
            f1:hasDate ?date ;
            f1:hasSeason ?season .
        } 
    } 

} LIMIT 1`;

  return infoSparqlQuery;
}

export function getNationalities(callBack) {
  const queryString = `prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix f1: <http://www.oxfordsemantic.tech/f1demo/>

# Outer query - calculate result
SELECT  DISTINCT ?nationality WHERE {
  ?driver a f1:Driver;
      f1:hasNationality ?nationality .
} ORDER BY ?nationality `;

  //const startTime = performance.now();
  fetch(
    Constants.RDFoxURL + "?query=" + encodeURIComponent(queryString), //.replace(/%20/g, "+"),
    {
      headers: headers,
      mode: "cors",
    }
  )
    .then((response) => response.json())
    .then((jsonResponce) => {
      //console.log("response ", jsonResponce);
      const nations = jsonResponce.results.bindings.map((row) => {
        return row.nationality.value;
      });
      //console.log("mapped data ", driverData);
      callBack(nations);
    })
    .catch((e) => {
      console.log("Error calling RDFox: ", e);
      callBack([]);
    });
}

export function fetchInfoData(queryString, callBack) {
  let headers = new Headers();
  headers.append("accept", "application/sparql-results+json");
  headers.append("content-type", "application/sparql-query");
  const startTime = performance.now();
  fetch(
    Constants.RDFoxURL + "?query=" + encodeURIComponent(queryString), //.replace(/%20/g, "+"),
    {
      headers: headers,
      mode: "cors",
    }
  )
    .then((response) => response.json())
    .then((jsonResponce) => {
      const endTime = performance.now();
      //console.log("response ", jsonResponce);
      if (jsonResponce.results.bindings.length === 0) {
        console.log("Call for the totals returned no results.");
        callBack({
          errorMessage: "Error retrieving the totals from the datastore.",
        });
        return;
      }

      const infoData = jsonResponce.results.bindings.map((row) => {
        return {
          raceCount: parseInt(row.raceCount.value),
          driverCount: parseInt(row.driverCount.value),
          resultCount: parseInt(row.resultCount.value),
          seasonCount: parseInt(row.seasonCount.value),
          constructorCount: parseInt(row.constructorCount.value),
          circuitCount: parseInt(row.circuitCount.value),
          timeTaken: Math.round(endTime - startTime),
        };
      });
      //console.log("mapped data ", infoData);
      callBack(infoData[0]);
    })
    .catch((e) => {
      console.log("Error calling RDFox: ", e);
      callBack({
        errorMessage: "Error retrieving the totals from the datastore.",
      });
    });
}
